/* Loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  flex-direction: column;
}

.loader-svg {
  width: 50px;
  height: 50px;
}

.rotating {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

.loader-container.main-loader-class {
  margin: 0px;
}
.loaderText{
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-align: center;
}
.main-loader-class .loaderText{
  color: black;
}