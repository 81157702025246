.page-type {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.body-component-container {
  padding: 10px;
}

.show-btn .input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 3em;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  border: 4px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.input-radio.on:checked {
  box-shadow: 0px 0px 0px 2px #11a8ad; 
  background-image: var(--ncog-gradient);
}

.input-radio.off:checked {
  box-shadow: 0px 0px 0px 4px #eb0000;
  background-color: #ff5151;
}

.box-common-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 14px;
  background: var(--primary, #fff);
  box-shadow: 0px 4px 20px 0px rgba(197, 220, 206, 0.51);
  height: 55px;
  padding: 20px;
  margin: 0 15px;
}

.show-checkbox-box-type {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

label.label-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.show-btn.show-text {
  color: var(--text_1-1, #a3abad);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

label.label-radio span,
span.pipe-symbol {
  color: var(--text_1-1, #a3abad);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

label.label-radio span.active {
  color: var(--secondary, #131717);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.show-btn {
  display: flex;
  gap: 20px;
  align-items: center;
}

.page-type-check-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-message-box-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e2e8eb;
  cursor: pointer;
  padding: 10px;
  flex-wrap: wrap;
}

.message-box-container {
  padding: 15px 15px 0;
  height: calc(100vh - 170px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.user-subject-section.Readed{
  opacity: 50%;
}
.text{
  background: var(
    --accent_g,
    linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)
  );
  
}
.foldersList {

 position: relative; 
}
.foldersList .folders{
  padding: 0;
  position: absolute;
  top: 24px;
  padding: 5px 5px;
  width: 250px;
  left: -82px;
  height: auto;
  max-height: 300px;
  background: white;
  box-shadow: 0px 0px 16px 0px #ccc;
  z-index: 99;
  max-height: 400px;
  overflow: auto;
}
.menu-settings{
  position: relative;
}
.menu-settings .settings{
  position: absolute;
  width: 220px;
  background: white;
  z-index: 99;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  margin-top: 10px;
  left: -95px;
  height: auto;
  box-shadow: 0px 0px 16px 0px #ccc;
}
.menu-settings .settings{
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  padding: 13px;

}
.menu-settings .settings p{
margin: 0;
display: flex;
align-items: center;

}
.menu-settings .settings p span{
  margin-left: 10px;
  color: #202124;

}
.folders li {
  padding: 8px 5px;
  display: flex;
  word-break: break-word;
}
.folders li svg {
  width: 30px;
  margin-right: 5px;

}
.noInternetdiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: '150px';
  text-align: center;
  background-color: white;
  color: black;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 9;
}
.noInternetdiv .title{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.noInternetdiv .message{
   font-size: 15px;
}

.row-message-box-child .user-subject-section.Readed{
  opacity: 50%;
}
.user-subject-section {
  color: var(--secondary, #131717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 55%;
  padding-left: 30px;
}
.user-subject-section span {
  width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.ncog-seperation {
  stroke-width: 1px;
  color: var(--text_1-1, #a3abad);
  float: right;
}

.check-box-ele-user {
  display: flex;
  align-items: center;
}

.user-date-section {
  color: var(--text_1, #36494d);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.row-message-box-child.Readed .user-details-section {
  opacity: 50%;
}

.user-details-section .user-profile-name {
  width: 8rem;
  max-width: 8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.user-details-section .user-profile-useremail {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-message-div {
  height: 100%;
  background: var(--primary_1, #eef4f2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
}
.search-icon-no-message,
.no-message-yet-ele,
.no-message-yet-ele-para {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-icon-no-message, .no-message-yet-ele, .no-message-yet-ele-para {
  padding: 20px 15px 0;
  text-align: center;
}

.no-message-yet-ele {
  color: var(--secondary, #131717);
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.box-common-header-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.page-index-division {
  font-size: 12px;
  font-weight: 100;
  line-height: normal;
}

span.current-page {
  color: var(--text_1, #36494d);
  font-size: 13px;
  font-weight: 600;
}

.box-common-header-actions-btn {
  justify-content: center;
  display: flex;
}
.encrypted-message-header-ele {
  display: flex;
  justify-content: space-between;
}

.encrypted-message-header-ele .actions {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
}

.topic-ele h2 {
  color: var(--secondary, #131717);
  /* font-family: Poppins; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.topic-ele p {
  color: var(--secondary, #131717);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 85%;
  margin: 0;
}

.topic-ele {
  display: grid;
  gap: 10px;
}

.encrypted-message-parent-ele {
  gap: 20px;
}

.actions.date-encrypt h4 {
  color: var(--text_1, #36494d);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.actions.date-encrypt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.encrypted-msg-content {
  border-radius: 16px;
  background: var(--primary_1, #eef4f2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  margin-top: 15px;
}

.encryption-content-div {
  display: flex;
  padding: 60px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--primary_1, #eef4f2);
  height: 100%;
}

.encrypted-alert {
  border-radius: 10px;
  background: rgba(19, 23, 23, 0.4);
  backdrop-filter: blur(7px);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.emcrypted-child-content div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emcrypted-child-content {
  background: rgba(19, 23, 23, 0.4);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-top: 15px;
  padding: 25px;
}
.encrypted-content-org {
  color: var(--strokes-icons, #e2e8eb);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  word-break: break-all;
  width: 100%;
  max-width: 35rem;
}

.encrypted-heading {
  color: var(--primary, #fff);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

button.btn-encrypt-view-msg {
  display: flex;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 14px;
  background: var(--secondary, #131717);
  color: white;
}

.user-profile-name.inbox {
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.position-uncover-checkbox {
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 5px 10px;
  border-radius: 10px;
}

.position-uncover-checkbox div {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: var(--text_1-1, #a3abad);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
