/* Header */
.header-container {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.user-profile-header,
.user-profile-header-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.user-profile-pic {
  width: 30px;
  min-width: 30px;
  height: 30px;
  background: #c9d5d1;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 30px;
}

.chain-icon {
  width: 30px;
  height: 30px;
  border-radius: 25px;
}

.balance-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-right: 40px;
}

.user-profile-username {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile-useremail {
  color: var(--text_1-1, #a3abad);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.user-profile-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.balance-text {
  color: var(--text_1-1, #a3abad);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.search-header {
  display: flex;
  width: 335px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--strokes-icons, #e2e8eb);
  background: var(--primary_1, #eef4f2);
  position: relative;
  margin-left: 10px;
}

.search-header input[type="text"] {
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  background: none;
  color: var(--text_1-1, #a3abad);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}

.message-box-container {
  scrollbar-width: 0;
  border-radius: 0;
}

.message-box-container::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: transparent;
  border-radius: 10px;
}

.message-box-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: transparent;
}

.message-box-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.header-btn-web3-id {
  opacity: 90%;
}

.header-btn-web3-id img {
  cursor: pointer;
}

.chain-icon img {
  width: 100%;
  height: 100%;
}

/* Responsiveness */

@media (max-width: 768px) {
  .header-container {
    padding: 10px;
  }

  .user-profile-header {
    gap: 10px;
  }

  /* .user-profile-pic {
    width: 25px;
    min-width: 25px;
    height: 25px;
  } */

  .settings-header {
    margin-right: 15px;
  }

  .chain-icon {
    width: 25px;
    height: 25px;
  }

  .balance-header {
    gap: 10px;
    margin-right: 20px;
  }

  .user-profile-username {
    font-size: 13px;
  }

  .user-profile-useremail {
    font-size: 10px;
  }

  .balance-text {
    font-size: 8px;
  }

  .search-header {
    width: 250px;
    padding: 8px 15px;
    margin-left: 5px;
  }

  .search-header input[type="text"] {
    height: 25px;
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .pagination-controls{
    padding: 10px 0
  }
  .show-checkbox-box-type{
    margin-right: 0;
  }
  .header-container {
    flex-wrap: wrap;
  }
   .header-container .bars.cursor-pointer{
    margin-top: 5px;
    margin-right: 6px;
   }
  .user-profile-header {
    gap: 5px;
    order: 2; /* Move user profile to the right */
    margin-right: 0;
   /* Add top margin for spacing */
  }

  /* .user-profile-pic {
    width: 20px;
    min-width: 20px;
    height: 20px;
  } */

  .settings-header {
    margin-right: 10px;
  }

  .chain-icon {
    width: 20px;
    height: 20px;
  }

  .balance-header {
    display: none;
  }

  .user-profile-username {
    font-size: 11px;
  }

  .user-profile-useremail {
    font-size: 8px;
  }

  .search-header {
    width: 100%; /* Make search full width */
    margin-left: 0;
    order: 1; /* Move search to the top */
  }

  .search-header input[type="text"] {
    height: 20px;
    font-size: 8px;
  }
}