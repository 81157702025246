.settings-function-parent-container {
  position: absolute;
  top: 50px;
}

:root {
  --ncog-gradient: linear-gradient(97deg, rgba(24, 240, 110, 1), rgba(11, 109, 224, 1));
}

/* src/Settings.css */
body.dark-mode {
  background-color: #121212;
  color: white;
}

.settings-page {
  padding: 20px;
}

.setting-item {
  margin-bottom: 20px;
}

label {
  display: flex;
  align-items: center;
}

.settings-function-parent-container.profile-container {
  width: auto;
}

.settings-container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.toggle-section-settings {
  margin-bottom: 30px;
}

.toggle-section-settings h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.toggle-settings {
  position: relative;
  display: inline-block;
  align-items: end;
  justify-self: end;
  max-width: 4rem;
  height: 34px;
}

.toggle-settings input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  align-items: end;
  cursor: pointer;
  width: 4rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #11a8ad;
}

input:focus + .slider {
  box-shadow: 0 0 1px #11a8ad;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.save-settings-btn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 60px;
  gap: 10px;
  width: 100%;
  max-width: 498px;
  height: 57px;
  background: linear-gradient(97.48deg, #18f06e -7.9%, #0b6de0 117.63%);
  border-radius: 14px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: none;
  color: white;
  outline: none;
}

.save-settings-btn {
  display: flex;
  justify-content: center;
  width: fit-content;
  float: inline-end;
}

.save-settings-btn button.success,
.save-settings-btn button.loading {
  background-color: #45a049;
}

.toggle-section-settings {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
body.light-mode {
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}
body.dark-mode {
  background-color: #1a1919;
  color: #999;
}

.settings-function-parent-container.profile-container {
  width: 30rem;
}

label.toggle-settings {
  display: flex;
  margin-left: auto;
}
.settings-page h1 {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.common-settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}

.settings-json-container {
  width: auto;
  border-radius: 16px;
}
.label-text-settings {
  width: 100%;
  height: 21px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #131717;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.settings-catagery-section {
  display: inline-grid;
  gap: 25px;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 700;
  height: fit-content;
  position: relative;
  top: 0;
}
.settings-catagery-content {
  display: inline-grid;
  gap: 25px;
  padding-top: 25px;
  padding-left: 25px !important;
  padding-bottom: 25px;
  font-weight: 600;
  height: fit-content;
  padding-right: 70px !important;
  width: auto;
}

.time-zone-select {
  margin-left: 10px;
  padding-left: 10px;
  border-radius: 10px;
  width: 100% !important;
  border: #cccccc solid 2px;
  background: #fff; 
  height: 41px;
  color: rgba(0, 0, 0, 0.47);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.camera-icon {
  position: relative;
  background: #fff;
  padding: 6px;
  border-radius: 50%;
  top: 3rem;
  right: 3rem;
  height: 50px;
}

.width-150 {
  width: 150px !important;
}

.height-150 {
  height: 150px !important;
}

svg#Capa_1 {
  margin-top: -3px;
}

.box-shadow {
  box-shadow: 0px 4px 20px 0px rgba(197, 220, 206, 0.51);
}

.custom-tags {
  /* height: 8rem; */
  width: auto !important;
}

.badge.bg-secondary.bg-gradient.me-1.pe-1.justify-content-between {
  height: fit-content;
}

.badge.bg-secondary.bg-gradient.me-1.pe-1.justify-content-between {
  background-image: none !important;
  background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)) !important;
  padding-bottom: 10px;
}

.settings-filter-section .form-control.h-auto.d-inline-flex.flex-wrap {
  gap: 3px;
  width: auto;
}

.setting-list:hover {
  border-bottom: #18f06e solid 1px !important;
}

.f-size-90 {
  font-size: 90px !important;
}

.f-weight-300 {
  font-weight: 300 !important;
}
@media screen and (max-width: 539px) {
  .settings-catagery-section {
      position: relative;
      background-color: #fff;
      width: 200%;
      text-wrap: nowrap;
      height: auto;
      top: 0;
      left: 0;
      z-index: 100;
      overflow-y: auto;
      transition: 0.3s ease;
      padding: 15px; /* Original padding */
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      padding: bottom;
  }

  .settings-catagery-section.show-sidebar {
      display: block;
      width: max-content; /* Show when sidebar is toggled */
  }

  .settings-catagery-content {
      width: 100%; /* Full width content when sidebar is hidden */
      padding-left: 0;
      padding-right: 0;
  }
}