@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@import url('./assets/css/signIn.css');

:root {
    --ncog-gradient: linear-gradient(97deg, rgba(24, 240, 110, 1), rgba(11, 109, 224, 1));
    --accent_g: linear-gradient(97deg, #18f06e -20%, #0b6de0 120%);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}
body {
    overflow-x: hidden;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.gap-10 {
    gap: 10px;
}
.gap-20 {
    gap: 20px;
}
.gap-40 {
    gap: 40px;
}

.home,
.reports,
.products {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background-color: #fff;
    color: #000;
}

.leftsidebar-toggle-menu .parent-component-div-align {
    margin-left: 0px;
}
.parent-component-div-align {
    position: absolute;
    top: 0;
    width: -webkit-fill-available;
    width: -moz-available;
    height: 100%;
    margin: 0 0px 0px 20rem;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.parent-no-layout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    width: 95%;
    margin: auto;
}

.dmail-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dmail-text span {
    width: 35rem;
}

.dmail-text {
    color: var(--text_1-1, #a3abad);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    justify-content: center;
}

.initial-layout-start.footer-auth {
    padding: 40px;
    display: flex;
    gap: 25px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    flex-direction: column;
}

.btn-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.btn-actions a.btn-auth {
    color: var(--text_1-1, #a3abad);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    text-decoration-line: underline;
    border: none;
    background: none;
    width: 33.333%;
    padding: 10px;
}
.parent-component-div-initial-layout {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header-signup-value h4 {
    color: var(--secondary, #131717);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    display: flex;
    justify-content: center;
}

.header-signup-value {
    display: grid;
    gap: 20px;
}

.header-signup-value span {
    align-self: stretch;
    color: var(--text_1, #36494d);
    text-align: center;
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

/* sdskdn */

::selection {
    background: #4158d0;
    color: #fff;
}
.wrapper form {
    padding: 10px 130px 50px 130px;
}
.wrapper form .field {
    height: 50px;
    width: 100%;
    margin-top: 30px;
    position: relative;
}
.wrapper form .field input {
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 17px;
    padding-left: 20px;
    border: 1px solid lightgrey;
    border-radius: 15px;
    transition: all 0.3s ease;
}
.wrapper form .field input:focus,
form .field input:valid {
    border-color: #4158d0;
}
.wrapper form .field label {
    position: absolute;
    top: 50%;
    left: 20px;
    color: #999999;
    font-weight: 400;
    font-size: 17px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}
form .field input:focus ~ label,
form .field input:valid ~ label {
    top: 0%;
    font-size: 16px;
    color: #4158d0;
    background: #fff;
    transform: translateY(-50%);
}
form .content {
    display: flex;
    width: 100%;
    height: 50px;
    font-size: 16px;
    align-items: center;
    justify-content: space-around;
}
form .content .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}
form .content input {
    width: 15px;
    height: 15px;
    background: red;
}
form .content label {
    color: #262626;
    user-select: none;
    padding-left: 5px;
}
form .content .pass-link {
    color: "";
}
form .field input[type="submit"] {
    color: #fff;
    border: none;
    padding-left: 0;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background: #0d67fe;
    transition: all 0.3s ease;
}
form .field input[type="submit"]:active {
    transform: scale(0.95);
}
form .signup-link {
    color: #262626;
    margin-top: 50px;
    text-align: center;
}
form .pass-link a,
form .signup-link a {
    color: #4158d0;
    text-decoration: none;
}
form .pass-link a:hover,
form .signup-link a:hover {
    text-decoration: underline;
}

.field.submit-btn-form {
    margin-bottom: 30px;
}

.input-values-sign-up {
    display: flex;
    padding: 15px 25px;
    border-radius: 14px;
    border: 1px solid var(--text_2, #c6cfd4);
    width: 100%;
}

.input-field-div input#username {
    width: 100%;
    max-width: 400px;
    border: none;
    outline: none;
    color: var(--text_1-1, #000);
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.5px;
}

.input-field-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

span.domain-input-default {
    background: var(--ncog-gradient);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.input-field-div input#name {
    width: 22.5rem;
    border: none;
    outline: none;
    color: var(--text_1-1, #000);
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.5px;
}

button.confirm-btn-sign {
    display: flex;
    padding: 18px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 14px;
    background: var(--text_2, #c6cfd4);
    border: none;
    outline: none;
    width: 100%;
    color: var(--primary, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.sign-up-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 100px 80px;
    width: 100%;
    max-width: 650px;
    height: 500px;
    background: #ffffff;
    box-shadow: 0px 12px 49.6px rgba(197, 220, 206, 0.49);
    border-radius: 20px;
    gap: 40px;
}
.btn-confirm-sign {
    width: 100%;
}

@media screen and (max-width: 1540px) and (max-height: 700px) {
    /* CSS rules go here */
    .sign-up-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 90px 72px;
        gap: 50px;
        width: 600px;
        max-width: 650px;
        height: 450px;
        background: #ffffff;
        box-shadow: 0px 12px 49.6px rgba(197, 220, 206, 0.49);
        border-radius: 20px;
        gap: 40px;
    }
}
@media screen and (max-width: 1350px) {
    .message-box-position.normal-compose {
        transform-origin: bottom right;
        border-radius: 0;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: 1000;
    }
    body .search-header {
        width: 250px;
    }
}

.initial-div-ele-container {
    display: flex;
    justify-content: center;
}

.parent-no-layout-containers.common-header-ele {
    display: flex;
    max-width: 706px;
    padding: 30px 100px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 20px;
    background: var(--primary, #fff);
    box-shadow: 0px 12px 49.6px 0px rgba(197, 220, 206, 0.49);
}
.user-check-section {
    display: flex;
    gap: 5px;
    justify-content: start;
    align-items: center;
    width: 5%;
}
/* CSS  */
.user-check-section .check-box-ele-user{
    margin:auto;
}
.user-check-section .check-box-ele-user #html{
    margin: auto;
}
.box-common-header.web3-id {
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
}

.web3-id-header-name {
    width: 72px;
    height: 27px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #131717;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.action-web3-type-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 80px 80px;
    gap: 20px;
    isolation: isolate;
    width: 704px;
    min-height: 400px;
    background: #f5f8fa;
    border-radius: 30px;
    height: auto;
    margin: 0 auto;
}

.web3-parent-class {
    display: flex;
    padding: 40px;
}
.web3-actions-names {
    width: 400px;
}

.name-action-dropdown-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 20px;
    gap: 100px;
    width: 85%;
    height: 44px;
    border: 1px solid #c6cfd4;
    border-radius: 14px;
    outline: none;
    position: relative;
    cursor: pointer;
}

.div-class-web3-parent-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 35px;
    gap: 14px;
    width: 85%;
    height: 45px;
    background: #eef4f2;
    border-radius: 14px;
    margin: 10px 0;
    cursor: pointer;
}

.div-class-web3-parent-child.active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 35px;
    gap: 14px;
    height: 45px;
    background: #1b2020;
    border-radius: 14px;
    color: white;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

button.claim-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 35px;
    width: 544px;
    height: 57px;
    background: linear-gradient(97.48deg, #18f06e -20%, #0b6de0 120%);
    border-radius: 14px;
    border: none;
    outline: none;
    color: #fff;
}

button.claim-btn.name-input-class {
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
}

button.claim-btn.error {
    background: var(--text_2, #c6cfd4);
}

h4.claim-new-name-title {
    font-style: normal !important;
    font-weight: 1000 !important;
    font-size: 18px;
    line-height: 21px;
    color: #131717;
}

p.claim-new-name-description {
    width: 544px;
    height: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #a3abad;
}

.input-classname-WebId input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 40px;
    gap: 10px;
    width: 544px;
    height: 57px;
    border: 1px solid #c6cfd4;
    border-radius: 14px;
    color: #a3abad;
}

.claim-new-name.container {
    display: grid;
    gap: 50px;
}

.claim-new-name.container.connect-assert-web3 {
    gap: 30px;
}

.choose-asset-type {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 10px 40px;
    gap: 100px;
    width: 544px;
    height: 57px;
    border: 1px solid #c6cfd4;
    border-radius: 14px;
    position: relative;
    cursor: pointer;
}

span.choose-asset-span {
    height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #1b2020;
}

.choose-asset-type-airrow svg {
    position: absolute;
    top: 15px;
    right: 10px;
}

.choose-asset-type-airrow {
    position: relative;
}

.web3-common-dropdown-container {
    position: absolute;
    top: 50px;
}

.web3-common-dropdown-container {
    position: absolute;
    top: 70px;
    left: -1px;
    width: 101%;
    background: white;
    border-radius: 20px;
    padding: 40px;
}

.div-class-dropdown-value-child {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choosen-name-down-arrow {
    display: flex;
}

.web3-common-dropdown-container.names {
    background: #f8faf9;
    padding: 20px;
    top: 45px;
}

.div-class-foot-asset-child {
    display: grid;
    grid-template-columns: 4fr 0.5fr 0.5fr;
    align-items: center;
    padding: 15px 0px;
}

.asset-list-type {
    font-style: normal;
    font-weight: 1000;
}

.asset-name-type-value {
    height: 21px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #a3abad;
}

.asset-name-type-value span.asset-count {
    color: #18f06e;
    font-style: normal;
    font-weight: 700;
}

.error-response-success {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
}

.error-response-success {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    margin-top: 10px;
}

.error-response-success.error span {
    color: var(--errors, #ff3d40);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.error-response-success.success span {
    color: var(--green, #45c678);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.input-values-sign-up.available {
    border: 2px solid #18f06e;
}

button.confirm-btn-sign.confirm-class {
    border-radius: 14px;
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
}

button.confirm-btn-sign.name-input-class {
    border-radius: 14px;
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
}

.input-values-sign-up-parent {
    width: 100%;
}

/* CONNECT WALLET */

.initial-div-ele-actions {
    display: flex;
    width: 186px;
    height: 186px;
    padding: 49px 46px 48px 46px;
    justify-content: center;
    align-items: center;
    border-radius: 276.343px;
    border: 6px solid rgba(198, 207, 212, 0.25);
    background: linear-gradient(334deg, #eaeef0 -1.83%, #fff 86.2%);
    position: relative;
}

.initial-div-ele-actions svg {
    display: none;
}

.initial-div-ele-actions::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 105px;
    height: 80px;
    background: url(./assets/images/ncog-logo.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.initial-div-ele-text {
    align-self: center;
    color: var(--text_1, #36494d);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 20rem;
    margin: 40px auto;
    text-wrap: wrap;
}

.parent-no-layout-containers.common-header-ele.metamask {
    margin-top: 50px;
}

/* CONNECT WALLET */

/* compose email  */
.inbox-container {
    height: 100%;
}
.common-message-containder {
    position: relative;
    height: 100%;
}

.message-box-position {
    width: 60rem;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 14px;
    background: var(--primary, #fff);
    box-shadow: 0px 20px 45px -14px rgba(76, 88, 81, 0.6);
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 2;
}

.header-compose-email {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    color: var(--secondary, #131717);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.email-username.reply-user.compose {
    height: 50px;
    margin-top: 5px;
    display: flex;
    justify-content: normal;
    width: auto;
}

.send-alert-body-content.reply-email-user .email-username {
    text-align: center;
    padding: 10px;
    display: grid;
    border-bottom: 3px solid #f1f1f1;
    grid-template-columns: 1fr 1fr;
}

input.reply-user-input {
    height: 23px;
    border: none;
    outline: none;
    width: 45rem;
}

.quil-text-editor {
    margin: 0;
    background: white;
    border-radius: 15px;
    position: relative;
}

.email-username.reply-user.compose span {
    display: flex;
}
.email-username.reply-user.compose span {
    display: flex;
    color: var(--text_1-1, #a3abad);
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.email-username.reply-user.compose.no-border {
    border-bottom: none;
}

.send-compose-email {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-radius: 14px;
    background: linear-gradient(103deg, #18f06e -12.71%, #0b6de0 127.93%);
    /* position: absolute; */
    right: 20px;
    bottom: 20px;
    cursor: pointer;
}

.send-compose-email div {
    display: grid;
    color: #f5f8fa;
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.choose-asset-type-airrow svg {
    position: absolute;
    top: 9px;
    right: 10px;
}

/* compose email  */

button.btn-transfer.active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 35px;
    gap: 14px;
    /* width: 267px; */
    height: 45px;
    background: #1b2020;
    border-radius: 14px;
    color: white;
}

.to-wallet-transfer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

button.btn-transfer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 35px;
    gap: 14px;
    width: 267px;
    height: 45px;
    background: #eef4f2;
    border-radius: 14px;
    flex: none;
    order: 1;
    flex-grow: 1;
    outline: none;
    border: none;
}

.preview-name {
    font-style: normal !important;
    font-weight: 1000 !important;
    font-size: 18px;
    line-height: 21px;
    color: #131717;
}

label.toggle {
    display: flex;
    justify-content: flex-start;
    width: 20px;
}

.input-classname-WebId.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.class-msg-component-render {
    display: flex;
    width: -webkit-fill-available;
    margin: 80px;
    height: auto;
    padding: 60px;
    gap: 10px;
    border-radius: 12px;
    background: #fff;
    line-height: normal;
    word-break: break-all;
    width: -moz-available;
}

.encrypted-msg-content.decrypt {
    height: auto;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
    border: none;
}
.input-compose-msg {
    height: auto;
    max-height: 600px;
    overflow: auto;
    padding: 0px 15px;
}
.ql-toolbar.ql-snow {
    box-sizing: border-box;
    padding: 8px;
    margin-left: 27rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.position-abo-messsage {
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--text_1-1, #a3abad);
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

input.reply-user-input {
    height: 100% !important;
    border: none;
    outline: none;
    width: 100%;
    height: 25px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #131717;
}

.user-details-section {
    display: flex;
    align-items: center;
}
.message-section.user-details-section {
    width: 20%;
    gap: 8px;
    padding: 0 30px;
    position: relative;
}
.user-details-section.message-section:before {
    content: '';
    height: 15px;
    width: 1px;
    display: block;
    position: absolute;
    left: 0;
    background-color: #A3ABAD;
}
.user-details-section.message-section::after {
    content: '';
    height: 15px;
    width: 1px;
    display: block;
    position: absolute;
    right: 0;
    background-color: #A3ABAD;
}
input {
    outline: none;
    box-sizing: border-box;
}

.user-details-section.child-ele-encrypt {
    gap: 20px;
}

.ant-modal.modal-alert-header.add-quick-user-send-email .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 850px;
    height: auto;
    left: calc(50% - 850px / 2);
    top: calc(50% - 540px / 2);
    background: #dddddd;
    box-shadow: 0px 29px 81.1px rgba(0, 0, 0, 0.29);
    border-radius: 20px;
}

input.reply-user-input.reply {
    background: none;
    padding-left: 10px;
}

.quil-text-editor.reply {
    margin: 35px 0;
    height: 18rem;
    overflow: hidden;
}

.email-username.reply-user.compose input {
    padding-left: 10px;
}

.div-class-foot-asset-child .asset-list-icons {
    cursor: pointer;
}
.header-btn-web3-id a {
    color: white;
}

.start-icon-div {
    display: flex;
}

.refresh-append {
    display: flex;
    justify-content: right;
    margin-left: 8rem;
}

span.count-type {
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 27px;
    background: var(--accent_o, linear-gradient(100deg, #ffa16c -3.13%, #ff3d40 126.56%));
    width: 45px;
    height: 30px;
    overflow: hidden;

}

.cursor-poiner-common {
    cursor: pointer;
}

.header-btn-web3-id img {
    width: 7rem;
}

.ncog-blur-component {
    opacity: 25%;
    cursor: no-drop;
}

.web3-redirect-component {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 90%;
    position: relative;
    bottom: 40px;
    width: 100%;
    background: #1b2020;
    height: 75px;
    border-radius: 18px;
}

.profile-body-logo {
    display: flex;
    justify-content: center;
}

.settings-page {
    margin: 20px;
    background: #eef4f2;
    padding: 20px;
    border-radius: 15px;
}

.row-message-box-child:hover {
    border-radius: 10px;
    background: var(--primary_1, #eef4f2);
}

ul.nav-menu-items {
    height: 100vh;
    padding-left: 0px !important;
    overflow: hidden;
    margin: 0;
}
.ant-modal.modal-loader-common .ant-modal-content {
    box-shadow: none;
    background: transparent;
}

.web-3-preview img {
    width: 50px;
}

.web-3-preview {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px;
}

.asset-list-preview.qr-code-preview {
    display: flex;
    flex-direction: column;
    padding: 20px 10px 10px 20px;
    gap: 10px;
    width: auto;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(197, 220, 206, 0.51);
    border-radius: 14px;
}

.asset-list-qr-code-preview {
    justify-content: center;
    display: flex;
    opacity: 70%;
}

.wallet-address-div {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    gap: 10px;
    width: 494px;
    height: 55px;
    background: #f5f8fa;
    border: 1px solid #e2e8eb;
    border-radius: 10px;
    margin-top: 10px;
}

.wallet-address-div span {
    width: 494px;
    height: 15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #1b2020;
}

.wallet-address-copy-section {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: auto;
    height: 38px;
    border: 1px solid #c6cfd4;
    border-radius: 10px;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #36494d;
}

.wallet-address-copy-parent {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.wallet-address-copy-section span {
    display: flex;
}

span.compose-cc-bcc {
    margin-left: 15px;
    cursor: pointer;
}

/* .message-box-position.both-carbon.copy {
    bottom: 120px;
}

.message-box-position.carbon {
    bottom: 90px;
} */

.folder-common-element {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-left: 25px;
    /* margin-bottom: 15px; */
    align-items: center;
    height: 30px;
}

.compose-emails-actions {
    /* position: absolute; */
    /* left: 0;
  height: 17%;
  width: 40rem;
  bottom: 20px; */
    display: flex;
    align-items: center;
}
.undo-redu-functionality {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.add-attachment-functionality {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.compose-email-bth-operations {
    display: flex;
    align-items: center;
    gap: 20px;
}

.text-selection {
    user-select: none;
}

.delete-functionality.cursor-pointer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.undo.cursor-pointer,
.redo.cursor-pointer {
    display: flex;
}

.btn-attachment.cursor-pointer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

span.add-file-component {
    color: var(--text_1-1, #a3abad);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.delete-functionality.cursor-pointer span {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .box-common-header.box-header-type {
  display: grid;
  grid-template-columns: 4fr .5fr .3fr;
  align-items: center;
  border-radius: 14px;
  background: var(--primary, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(197, 220, 206, 0.51);
  height: 55px;
  padding: 0 20px;
  margin: 0 15px;
} */

.quick-user.email {
    color: var(--text_1-1, #a3abad);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.div-class-folder-content {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.div-class-folder-content.active {
    border-radius: 10px;
    --accent_g: linear-gradient(97deg, rgba(24, 240, 110, 0.1) -20%, rgba(11, 109, 224, 0.1) 120%);
    background: var(--accent_g);
}

.page-type-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-class-dropdown-value-child.names {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    gap: 25px;
    width: auto;
    height: auto;
    background: #ffffff;
    margin-top: 10px;
    border-radius: 10px;
}

.div-class-dropdown-value-child.names:hover {
    background: #e6e6e6;
}

.profile-container .loader-container {
    margin: 0px;
}

.row-message-box-child.Unreaded.sort-added {
    grid-template-columns: 0.3fr 0.8fr 0.9fr 0.5fr 0.5fr;
}

.sort-icon-action-01 {
    position: relative;
}

.sort-icon-action-02 {
    position: absolute;
    top: 5rem;
    right: 0rem;
}

.message-box-container.sort-added {
    width: 78rem;
}

.contextMenu {
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1000;
    position: absolute;
}

.contextMenu--option-div {
    display: flex;
    flex-direction: column;
    background: #ededed;
    border-radius: 8px;
}

.contextMenu.copying-content {
    border-radius: 8px;
}

.contextMenu--option {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease;
}

.contextMenu--option:hover {
    background: #f2f2f2;
}

.contextMenu--option-div-line {
    margin-right: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.drag-image {
    background: var(--ncog-gradient);
    padding: 10px;
    color: white;
    font-size: 16px;
    text-align: center;
    position: absolute;
    top: -9999px; /* Move it out of view */
    left: -9999px; /* Move it out of view */
    border-radius: 8px;
    height: 3rem;
    width: 15rem;
}

.hover-reply {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 20px;
    gap: 45px;
    width: 90%;
    height: 34px;
    right: 11px;
    top: calc(50% - 34px / 2);
    background: linear-gradient(97.48deg, #18f06e -7.9%, #0b6de0 117.63%);
    border-radius: 8px;
}

.hover-reply div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.minimise-compose-div-element {
    align-items: center;
    bottom: 0;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 4px;
    height: 39px;
    justify-content: space-between;
    padding: 10px 25px 8px 15px;
    position: absolute;
    right: 0rem;
    width: 304px;
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
    border-radius: 10px;
    color: white;
}
.attachment-section-element {
    max-width: 60rem;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    gap: 20px;
}

.div-btn-action-compose-email {
    display: flex;
    align-items: center;
    margin: 20px 0;
    justify-content: space-between;
}

.header-compose-email .cursor-pointer {
    display: flex;
}

.attachment-file-rendering-method {
    opacity: 75%;
    margin-top: 10px;
}
.attachment-section-element::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background-color: transparent;
}

.attachment-section-element::-webkit-scrollbar {
    width: 1px;
    height: 5px;
    background-color: transparent;
    border-radius: 10px;
}

.attachment-section-element::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #45c678;
}

.attachment-section-element a {
    background-color: #f5f5f5;
    border: 1px solid transparent;
    font-weight: bold;
    margin: 0 0 9px;
    padding-right: 30px;
    padding-left: 20px;
    padding: 4px 4px 4px 8px;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.class-msg-component-render.decrypt-msg {
    display: block;
}

.attachment-msg-section {
    display: flex;
    flex-wrap: wrap;
    /* justify-content : space-evenly; */
    gap: 20px;
    padding: 50px 0 0 0;
}

.attachment-div-element {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    width: 15rem;
    height: 10rem;
}

.attachment-icon {
    justify-content: center;
    display: flex;
    align-items: center;
}

.attachment-icon svg {
    opacity: 60%;
}

.file-name-preview {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-profile-name.inbox.quick-send {
    max-width: 20rem;
    width: auto;
}

.minimise-compose-div-element .cursor-pointer {
    display: flex;
    gap: 8px;
}

.loader-container.normal-loader-div {
    height: auto;
    margin: unset;
}

.add-user-icon-quick.cursor-pointer.disable-value svg {
    opacity: 50%;
    cursor: no-drop;
}

.user-profile-name.inbox.quick-send {
    max-width: 22rem;
    width: 100%;
    height: 50px;
    display: grid;
    align-items: center;
}
.position-relative-cls {
    position: relative;
}

.position-absolute-cls {
    position: absolute;
}

.close-icon-quick-access.position-absolute-cls {
    top: 8px;
    right: 0;
    background: var(--ncog-gradient);
    width: 25px;
    border-radius: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-icon-quick-access-ele {
    width: 100%;
}
.user-date-section {
    display: flex;
    /* width: 7rem; */
    align-items: center;
    justify-content: end;
    text-align: end;
}
.user-encryption-section {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 20%;
}
.replay-action-disabled {
    cursor: no-drop;
}

.user-profile-name.inbox.mail-username {
    width: 100%;
}

.user-details-section.child-ele-encrypt .user-profile-name {
    width: auto;
    max-width: none;
}

.lock-logo {
    display: flex;
}

.attachment-div-element.d-flex-data {
    display: grid;
}

.attachment-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Toastify__toast--error {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}

.compose-sweet-alert button.swal2-cancel.swal2-styled.swal2-default-outline {
    background: #ffffff;
    border: #d9d9d9 solid 1px;
    color: rgba(0, 0, 0, 0.88);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.truncated-text {
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.message_show_more_button {
    border-radius: 30px;
    margin-top: 8px;
    height: 18px;
    width: 40px;
    border: none;
    background: #eef4f2;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px; /* Space between buttons */
    padding: 10px;
}

.pagination-controls button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: var(--primary_1, #eef4f2);
    color: #333;
    cursor: pointer;
    font-size: 14px;
}

.pagination-controls button:hover {
    background-color: #eef4f2;
}

.pagination-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.pagination-controls .active {
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
    color: white;
    border: none;
}

.pagination-controls .active:hover {
    background-color: #0056b3;
}

.items-per-page-select {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: var(--primary_1, #EEF4F2);
    color: #333;
    cursor: pointer;
    font-size: 14px;
}

.pagination-icon {
  padding: 8px 16px;
  background: none;
  cursor: pointer;
} 

.pagination-icon:hover {
  border-radius: 50%;
  background-color: #eef4f2;
}

.pagination-list-length {
  color: var(--text_1-1, #A3ABAD);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

svg {
  vertical-align: initial !important;
}

.event-date {
  background: var(--accent_g, linear-gradient(97deg, #18F06E -20%, #0B6DE0 120%));
  border-radius: 4px;
}

.event-date svg text {
  background: linear-gradient(97.48deg, #18F06E -20%, #0B6DE0 120%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-13 {
  font-size: 13px;
}

.trash-header{
  background: var(--primary_1, #EEF4F2) !important;
  border: none !important;
}

.trash-header .title{
  border: none !important;
}

.gradient-text{
  background: linear-gradient(97.48deg, #18F06E -20%, #0B6DE0 120%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-bg {
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
}
/* new css */
.sidebar-open {
    margin-left: -20rem;
}

.content-full-width {
    margin-left: 0px;
}
.show-checkbox-box-type {
    display: flex;
}
.dropdown-content {
    display: none;
}
.dropdown-content.show {
    display: block;
}
.ql-editor {
    padding: 20px 20px;
    min-height: 8rem !important;
    max-height: 12rem;
    overflow-y: auto;
}
@media (max-width: 1650px) {
    .hover-reply {
        gap: 40px;
    }
}
@media (max-width: 1600px) {
    .hover-reply {
        gap: 30px;
    }
}
@media (max-width: 1400px) {
    .user-details-section.message-section:before {
        content: none;
    }
    .message-section.user-details-section {
        padding: 0 10px;
    }
    .hover-reply {
        gap: 15px;
    }
}
@media (max-width: 1200px) {
    .hover-reply {
        gap: 15px;
    }
}
@media (max-width: 1169px) {
    .user-profile-header .user-profile-name,
    body .balance-header,
    body .user-profile-arrow {
        display: none;
    }
    body .parent-component-div-align {
        width: 100%;
        margin-left: 0 !important;
    }
    .nav-menu {
        margin-left: -20rem;
    }
    .sidebar-open {
        margin-left: 0;
    }
    .overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
        transition: opacity 0.3s ease;
    }
    .overlay.show {
        display: block;
    }
    .message-section.user-details-section {
        width: 25%;
    }
    .user-subject-section {
        padding-left: 15px;
        width: 50%;
    }
    body .balance {
        display: flex;
    }
}
.row-message-box-child.user-select-none.\+.Readed .user-subject-section.group{
    opacity: 50%;
}
@media (max-width: 767px) {
    html body{
        background-color: #F5F8FA;
    }
    body .settings-header {
        display: none;
    }
    body .header-container {
        padding: 15px;
        background-color: white;
    }
    .body-component-container {
        padding: 0;
    }
    body .search-header {
        width: 220px;
        padding: 5px 15px;
        border-radius: 14px;
        background: #f5f8fa;
        border: 1px solid #e2e8eb;
    }
    .box-common-header {
        margin: 0;
        padding: 10px;
        box-shadow: none;
        border-radius: 0 0 14px 14px;
    }
    .show-checkbox-box-type {
        display: block;
    }
    .message-box-container {
        padding: 15px 10px;
        height: calc(100vh - 135px) !important;
    }
    .dropdown-content {
        position: absolute;
        background-color: white;
        border: 1px solid #ddd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        padding: 5px 10px;
        border-radius: 10px;
        margin-top: 10px;
    }
    .show-btn {
        padding: 5px;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        gap: 10px;
        color: #131717;
    }
    .show-btn p {
        margin: 0;
    }
    .show-btn span {
        background: -webkit-linear-gradient(97.48deg, #18f06e -7.9%, #0b6de0 117.63%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .dropdown-content.show {
        display: block;
    }
    .ql-toolbar.ql-snow .ql-formats {
        margin-right: 0px;
    }
    .ql-editor {
        margin-top: 30px;
    }
    .position-abo-messsage {
        top: 45px;
    }
    .mobile-undo-redu-attachment {
        position: absolute;
        top: 50px;
        right: 15px;
        gap: 20px;
    }
    .ql-toolbar.ql-snow {
        margin-left: 0;
        border: 1px solid #e2e8eb;
        border-radius: 6px;
    }
    .encrypted-message-header-ele {
        flex-wrap: wrap;
    }
    .encrypted-message-header-ele .actions {
        order: -1;
        margin-left: auto;
        z-index: 1;
    }
    .topic-ele {
        margin-top: -20px;
        width: 100%;
    }
    .encryption-content-div {
        padding: 0;
    }
    .encrypted-alert {
        padding: 20px;
    }
    .topic-ele h2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
    }
    body .profile-container {
        max-width: 335px;
    }
    .header-compose-email {
        border: 2px solid #e2e8eb;
    }
    .ql-snow .ql-picker-label::before {
        display: inline-block;
        line-height: 22px;
        font-size: 10px;
    }
    .ql-snow .ql-picker.ql-font {
        min-width: 80px;
        width: auto;
    }
    .ql-snow .ql-picker.ql-size {
        min-width: 65px;
        width: auto;
    }
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 20px;
        padding: 3px 6px;
        width: 26px;
    }
    .class-msg-component-render {
        margin: 0;
        padding: 30px;
        background-color: #eef4f2;
        border: 1px solid #e2e8eb;
    }
    .encrypted-msg-content {
        gap: 0;
    }
    body button.compose-gradient-btn {
        background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
        border-radius: 14px;
        padding: 12px 20px;
        width: auto;
        position: fixed;
        bottom: 40px;
        right: 20px;
    }
    body button.compose-gradient-btn .compose-gradient-btn-child {
        display: none;
    }
    .row-message-box-child div#list-message-component .check-box-ele-user:first-child {
        display: none;
    }
    .user-encryption-section {
        width: 50%;
    }
    .user-subject-section {
        width: 92%;
        padding-left: 0;
        order: 2;
        padding-top: 10px;
        font-size: 14px;
    }
    .user-check-section {
        justify-content: end;
        width: 8%;
        order: 3;
        padding-top: 10px;
    }
    .user-details-section.message-section::after {
        content: none;
    }
    .message-section.user-details-section {
        width: 50%;
        padding: 0;
        position: relative;
    }
    .page-type {
        padding-left: 10px;
    }
    .page-type-check-box input{
        width: 20px;
        height: 20px;
    }
    .hover-reply {
        gap: 20px;
    }
    .row-message-box-child {
        padding: 10px 5px;
    }
    .gap-20{
        gap: 10px;
    }
    .ant-modal.modal-send-email-header.parent-div-content-reply-mail .ant-modal-content {
        padding: 15px 10px;
    }
    .sign-up-container {
        padding: 30px 20px 20px;
    }
    .btn-actions a.btn-auth {
        width: 100%;
    }
    .initial-layout-start.footer-auth {
        padding: 20px 15px;
        gap: 10px;
    }
}

.meta-text {
  color: var(--secondary, #131717);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal
}

.faucet-button {
  display: flex;
  height: 67px;
  padding: 22px 64px !important;
  justify-content: center;
  align-items: center;
  border-radius: 24px !important;
  background: var(--accent_g, linear-gradient(97deg, #18F06E -7.9%, #0B6DE0 117.63%));
  box-shadow: 0px 20px 45px -14px rgba(17, 170, 172, 0.50);
  border: none;
}

.faucet-button span {
  color: var(--primary, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.91;
}

.video-section {
  max-width: 550px;
  height: 222px;
  border-radius: 16px;
  background: var(--primary, #FFF);
  box-shadow: 0px 12px 49.6px 0px rgba(197, 220, 206, 0.49);
}

.video-section .video-content {
    max-width: 520px;
  height: 167px;
  border-radius: 12px;
  background: rgba(217, 217, 217, 0.23);
  margin: 7px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.common-header-ele-text{
    color: var(--secondary, #131717);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
@media(max-width:1400px){
    
.video-section {
    max-width: 450px;
    height: 222px;
    border-radius: 16px;
    background: var(--primary, #FFF);
    box-shadow: 0px 12px 49.6px 0px rgba(197, 220, 206, 0.49);
  }
  
  .video-section .video-content {
      max-width: 440px;
    height: 167px;
    border-radius: 12px;
    background: rgba(217, 217, 217, 0.23);
    margin: 7px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media(max-width:1200px){
    
    .video-section {
        max-width: 350px;
        height: 222px;
        border-radius: 16px;
        background: var(--primary, #FFF);
        box-shadow: 0px 12px 49.6px 0px rgba(197, 220, 206, 0.49);
      }
      
      .video-section .video-content {
          max-width: 360px;
        height: 167px;
        border-radius: 12px;
        background: rgba(217, 217, 217, 0.23);
        margin: 7px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    
@media(max-width:991px){
    
    .video-section {
        max-width: 690px;
        height: 222px;
        border-radius: 16px;
        background: var(--primary, #FFF);
        box-shadow: 0px 12px 49.6px 0px rgba(197, 220, 206, 0.49);
      }
      
      .video-section .video-content {
          max-width: 680px;
        height: 167px;
        border-radius: 12px;
        background: rgba(217, 217, 217, 0.23);
        margin: 7px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
        
@media(max-width:767px){
    
    .video-section {
        max-width: 510px;
        height: 222px;
        border-radius: 16px;
        background: var(--primary, #FFF);
        box-shadow: 0px 12px 49.6px 0px rgba(197, 220, 206, 0.49);
      }
      
      .video-section .video-content {
          max-width: 500px;
        height: 167px;
        border-radius: 12px;
        background: rgba(217, 217, 217, 0.23);
        margin: 7px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .common-header-ele-text{
        font-size: 16px;
      }
    }
        
@media(max-width:575px){
    
    .video-section {
        max-width: 550px;
        height: 222px;
        border-radius: 16px;
        background: var(--primary, #FFF);
        box-shadow: 0px 12px 49.6px 0px rgba(197, 220, 206, 0.49);
      }
      
      .video-section .video-content {
          max-width: 550px;
          width: 90% !important ;
        height: 167px;
        border-radius: 12px;
        background: rgba(217, 217, 217, 0.23);
        margin: 7px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }


.tutorial-text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.video-content-text {
  color: #36494D;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  top: 11.5rem;
  left: 1rem;
}

.white-space-nowrap{
  white-space: nowrap;
}

.gap-10 {
  gap: 10px;
}

.width-12 {
  width: 12px;
}

.width-2r {
  width: 2rem !important;
}

.width-11r {
  width: 11rem !important;
}

button.swal2-confirm {
  background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)) !important;
}

.email-contents {
  overflow-x: auto !important;
}

.email-contents::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.email-contents::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: transparent;
  border-radius: 10px;
}

.email-contents::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.sign-edit-btn {
    background-color: #11a8ad;
    border: none;
    width: 4rem;
    border-radius: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.save-signature-btn {
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
    color: #fff;
    border: none;
}

button.linear-gradient-btn{
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
    color: #fff;
    border: none;
    padding: 6px;
    border-radius: 4px;
}

.save-signature-btn:hover {
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)) !important;
    color: #fff !important;
    border: none;
}

.filter-section {
    position: absolute;
    top: 5rem;
    z-index: 1;
    width: 45rem;
    left: 3.5rem;
    box-shadow: 0px 4px 20px 0px rgba(197, 220, 206, 0.51);
}

.custom-date-picker {
    width: 36rem;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border:#18f06e solid 1px;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-3rel02).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-3rel02).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    color: #fff;
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)) !important;
}

.ant-picker-outlined:focus, .ant-picker-outlined:focus-within {
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
    background-color: #ffffff;
    border:#18f06e solid 1px !important;
}

.ant-picker-outlined:hover {
    border:#18f06e solid 1px !important;
    background-color: #ffffff;
}

.ant-picker-range .ant-picker-active-bar {
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)) !important;
}

.filter-section input.reply-user-input{
 font-weight: normal !important;
}

.filter-applied {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 10px;
    background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
    height: 10px;
    border-radius: 50%;
}

.group-emails {
    background: #eef4f2;
}

.margin-left-6 {
    margin-left: 6px;
}

.gap-5rem {
    gap: 5rem !important;
}

.padding-30{
    padding:30px !important;
}

.common-input {
    border-radius: 10px;
    border: #cccccc solid 2px;
    background: #fff;
    height: 50px;
    color: rgba(0, 0, 0, 0.47);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    padding: 10px;
}

.width-30 {
    width: 30px;
}

.height-30 {
    height: 30px;
}

.suggestion-list {
    top:10px;
    list-style-type: none;
    padding: 5px 10px 5px 10px;
    position: absolute;
    max-height: 200px;
    z-index: 10;
    margin-top: 40px;
    background: var(--primary, #fff);
    box-shadow: 0px 20px 45px 0px rgba(76, 88, 81, 0.6);
    border-radius: 14px;
  }

  .suggestion-body{
    overflow-y: auto;
    max-height: 180px;
  }

  .reply.suggestion-list {
    margin-top: 30px;
  }
  
  .suggestion-list-email {
    padding: 8px !important;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  
  .suggestion-list-email:hover {
    background-color: #eef4f2
  }

  .suggestion-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }
  
  .suggestion-body::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
  }
  
  .suggestion-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #45c678;
  }

  .app-menu{
    position: absolute;
    margin-top: 13rem;
    background: var(--primary, #fff);
    box-shadow: 0px 20px 45px -14px rgba(76, 88, 81, 0.6);
    width: 20rem;
    padding: 20px 20px;
    max-height: 20rem;
    z-index: 1;
    border-radius: 20px;
    gap: 1px;
    display: flex;
    flex-direction: column;
  }

  .app-menu-item{
    border-radius: 10px;
    padding: 5px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .app-menu-item-icon{
    background-color: #eef4f2;
    padding: 10px;
    border-radius: 14px;
  }

  .radius-4px{
    border-radius: 4px;
  }

  .max-h-15rem {
    max-height: 15rem;
  }

  .gmail_chip.gmail_drive_chip {
    box-sizing: unset;
  }
  
/* }
@media (max-width:360px){
    *{
        width: 100%;
    }
    .header-container{
        width: 100% !important;
    }
}
@media (max-width:340px){
    *{
        width: 110%;

    }
    .header-container{
        width: 130% !important;
    }
}
@media (max-width:310px){
    *{
        width: 120%;
    }
    
    .message-box-container{
        height: 500vw;
    }
    .header-container{
        width: 180% !important;
    }
} */
 .dragValue{
    padding:0 !important;
    display:flex;
    border-bottom:3px solid #f1f1f1;
 }
 .dragValue .mailContent{
    padding:0 !important;
    display:flex;
    flex:1;
    align-items:center;
    flex-wrap:wrap;
 }
 .dragValue .mailContent .maindiv{
    padding:5px 0px !important;
    text-align:start;
    display:flex;
    margin:0;
    flex-wrap:wrap;
    width:100%
 }
 .dragValue .mailContent .maindiv .title{
    cursor: pointer;

 }
 .dragValue .mailContent .maindiv .title:hover{
   text-decoration: underline;
 }


 .dragValue .mailContent .maindiv.hoverColor .mainDiv{
    border-radius: 54px;
    background:#bedee9 ;
 }

.dragValue .mailContent .maindiv.hoverColor .mainDiv:last-child:focus-visible{
    outline: 2px solid #bedee9;
}
 .dragValue .mailContent .maindiv .title{
    display:flex;
    align-items:center;
    font-style:italic;
    padding-left:20px;
    color: var(--text_1-1, #a3abad);

 }