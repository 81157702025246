
:root {
  --ncog-gradient: linear-gradient(97deg, rgba(24, 240, 110, 1), rgba(11, 109, 224, 1));
}

.modal-wrap-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-submit-modal {
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-top: 30px;
}
.button-submit-modal:hover {
  background-color: #0d67fe;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
.ant-modal.modal-alert-header.connect-wallet-account {
  margin-top: 10% !important;
}
.label-title-metamask {
  text-align: start !important;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 17px;
}

button.wallet-connect-btn {
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.08);
  height: 35px;
  padding: 0px 16px;
  border-radius: 14px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 14px;
}

.send-alert-body-content div {
  text-align: center;
  padding: 10px;
}

span.metamask-icon svg {
  height: 20px;
  width: 20px;
}

span.metamask-icon {
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.wallet-connect-btn.Connecting.MetaMask {
  opacity: 50%;
}

.loader {
  display: ruby;
}

.spinner {
  height: 5vh;
  width: 5vh;
  border: 6px solid rgba(0, 174, 239, 0.2);
  border-top-color: rgba(0, 174, 239, 0.8);
  border-radius: 100%;
  animation: rotation 0.6s infinite linear 0.25s;

  /* the opacity is used to lazyload the spinner, see animation delay */
  /* this avoid the spinner to be displayed when visible for a very short period of time */
  opacity: 0;
}

@keyframes rotation {
  from {
      opacity: 1;
      transform: rotate(0deg);
  }
  to {
      opacity: 1;
      transform: rotate(359deg);
  }
}

.modal-alert-header.connect-wallet-account.email-loader-send .ant-modal-content {
  background: transparent;
  box-shadow: none;
}

.send-alert-body-content.connect-wallet-account-div.send-email .label-title-metamask {
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.send-alert-body-content.send-email div {
  margin-left: 10px;
}

.label-title-metamask.email-send-div {
  color: white;
}

.ant-modal-mask.SendEmailLoader {
  background-color: rgb(201 198 198 / 45%);
}

.profile-drop-imgchain {
  animation: key1 0.3s ease infinite alternate;
}

@keyframes key1 {
  0% {
      transform: translateY(0px);
  }
  100% {
      transform: translateY(-10px);
  }
}

img.profile-drop-imgchain {
  height: 200px;
  width: 200px;
}

/* HTML: <div class="loader"></div> */
.loader-value {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader-value::before,
.loader-value::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader-value::before {
  border-color: #f03355 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.loader-value::after {
  margin: 8px;
}
@keyframes l16 {
  100% {
      transform: rotate(1turn);
  }
}

img.profile-drop-lock-loader {
  height: 250px;
}

.send-alert-body-content.connect-wallet-account-div.send-email .label-title-metamask.email-send-div,
.send-alert-body-content.connect-wallet-account-div.send-email .img-key-lock {
  padding: 0;
  height: 110px;
}

.send-alert-body-content.connect-wallet-account-div.send-email .label-title-metamask.email-send-div.key,
.send-alert-body-content.connect-wallet-account-div.send-email .img-key-lock.key {
  padding: 0;
  height: 70px;
}

img.profile-drop-imgchain.key {
  height: 100px;
  width: 100px;
}

form.form-class {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

input.input-class {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button.button-class {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.button-class:hover {
  background-color: #0056b3;
}

span.cursor-pointer.span-close {
  display: flex;
  justify-content: right;
}
.ant-modal.modal-send-email-header.parent-div-content-reply-mail {
  width: 100% !important;
  height: auto;
  background: #eef4f2;
  box-shadow: 0px 29px 81.1px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  max-width: 900px;
}
body .ql-snow .ql-editor blockquote {
  border-left: 0;
}
.ant-modal.parent-div-content-file-preview {
  width: 53% !important;
  height: 47rem;
  background: #eef4f2;
  box-shadow: 0px 29px 81.1px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
}

.pdf-viewer,
.pg-viewer {
  height: 40rem !important;
}

.file-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
  background-color: #eef4f2;
  padding: 10px;
}
.file-preview-header .header{
  cursor: pointer;
}

.pg-viewer-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.pg-viewer-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: transparent;
  border-radius: 10px;
}

.pg-viewer-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}
.ant-modal.modal-send-email-header.parent-div-content-reply-mail .ant-modal-content {
  background: transparent;
  box-shadow: none;
}

.email-username.reply-user {
  width: auto;
  height: 40px;
  margin-top: 17px;
  display: flex;
  justify-content: normal;
}
.send-alert-body-content.reply-email-user .email-username {
  text-align: center;
  padding: 10px;
  display: grid;
  grid-template-columns: 0.1fr 1fr;
}

input.reply-user-input {
  height: 23px;
  border: none;
  outline: none;
  width: 45rem;
}

input.default-cc.suggestion-input.compose {
  border-radius: 10px;
  outline: none;
  border: none;
  background: #808080c9;
  color: #fff;
  padding: 0 5px;
  font-size: 14px;
}

.email-username.reply-user span {
  display: flex;
}
.quil-text-editor .quill .ql-container.ql-snow .ql-editor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.quil-text-editor .quill .ql-container.ql-snow .ql-editor::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: transparent;
  border-radius: 10px;
}

.quil-text-editor .quill .ql-container.ql-snow .ql-editor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.send-reply-email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  border-radius: 14px;
  cursor: pointer;
  width: 10rem;
  background: linear-gradient(103deg, #18f06e -12.71%, #0b6de0 127.93%);
  color: white;
  margin-left: 3rem;
}

/* ring loader */

/* HTML: <div class="loader"></div> */
.loader-ring-reply {
  width: 20px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%, linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader-ring-reply::before,
.loader-ring-reply::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loader-ring-reply::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {
      transform: rotate(1turn);
  }
}

.label-title-check-box {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 40px;
}

.send-alert-body-content.connect-wallet-account-div.reply-email-user .email-username.reply-user {
  display: flex;
  border-bottom: 0.5px solid #e2e8eb;
}

.ant-modal.modal-loader-quick-send {
  margin-top: 3rem;
}

.field-records-containers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  height: auto;
  background: #eef4f2;
  border-radius: 10px;
}

.input-field-search-user {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e2e8eb;
  border-radius: 10px;
}

.quick-send-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

input.input-search-user {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
}

.user-rendering-dynamic {
  height: auto;
  max-height: 13rem;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
}

.user-details-section.user-profile-render-data {
  justify-content: center;
  gap: 10px;
}
.user-details-section.user-profile-render-data .user-profile-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #adcbc1;
  height: 35px;
  width: 35px;
  min-width: 35px;
}

.user-rendering-dynamic::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.user-rendering-dynamic::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: transparent;
  border-radius: 10px;
}

.user-rendering-dynamic::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.add-user-icon-quick {
  justify-content: center;
  display: flex;
  align-items: center;
}

.user-rendering-dynamic hr {
  height: 1px;
  opacity: 10%;
}

.user-profile-name.inbox.quick-send {
  max-width: 20rem;
  width: auto;
}

.folder-div-parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  height: auto;
  background: #eef4f2;
  border-radius: 10px;
}

.folder-input-element {
  width: 100%;
}

.folder-dynamic-content-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

input.input-folder {
  width: 100%;
  border: none;
  outline: none;
}

div.folder-name-update {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #a3abad;
  margin: 10px 0;
}

.folder-color-update-div {
  display: grid;
  width: 100%;
}

.input-div-folder {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  width: 306px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e2e8eb;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.folder-colder-update {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-evenly;
}

.folder-type-color {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.folder-type-color svg {
  height: 60px;
  width: 30px;
}

/* Add these styles to your CSS file */
.color-checked-green:checked + label::before {
  background-color: green;
}

.color-checked-red:checked + label::before {
  background-color: red;
}

.color-checked-blue:checked + label::before {
  background-color: blue;
}

.color-checked-purple:checked + label::before {
  background-color: purple;
}

.radio-button-section label {
  display: flex;
  align-items: center;
}

.radio-button-section input[type="radio"] {
  display: none;
}

.radio-button-section label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid #000;
  border-radius: 50%;
}

.color-checked-green:checked {
  box-shadow: 0px 0px 0px 2px #11a8ad; /* Use custom gradient for box-shadow */
  background-image: var(--ncog-gradient); /* Use custom gradient for background */
}

.radio-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-button-section svg {
  height: 30px;
  width: 28px;
}

button.add-folder-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 60px;
  gap: 10px;
  width: 332px;
  height: 57px;
  background: linear-gradient(97.48deg, #18f06e -7.9%, #0b6de0 117.63%);
  border-radius: 14px;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: white;
}

.folder-name-update.no-margin {
  margin: 5px 0;
}
.replay-attachment-file-rendering-method {
  height: 3rem;
  opacity: 75%;
  position: relative;
  top: 15px;
}
.replay-attachment-section-element {
  max-width: 92rem;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  gap: 20px;
}
.replay-attachment-section-element a {
  background-color: #ffffff;
  border: 1px solid transparent;
  font-weight: bold;
  margin: 0 0 9px;
  padding-right: 30px;
  padding-left: 20px;
  padding: 4px 4px 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: #404040;
  height: 50px;
}
.replay-attachment-section-element::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: transparent;
}
.replay-attachment-section-element::-webkit-scrollbar {
  width: 1px;
  height: 5px;
  background-color: transparent;
  border-radius: 10px;
}
.replay-attachment-section-element::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}
.replay-send-section {
  display: flex;
  justify-content: end;
}
.replay-send-section .btn-event{
  display: flex;
  align-items: center;
  margin-top: 0 !important;
  padding-right: 6px;
}
/* CustomAlert.css */
.custom-alert {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
}

.custom-alert.success {
  background-color: #4caf50;
} /* Green */
.custom-alert.info {
  background-color: #2196f3;
} /* Blue */
.alert.warning {
  background-color: #ff9800;
} /* Orange */

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.ql-toolbar.ql-snow .ql-formats {
  display: flex !important;
}

.mt-1rem {
  margin-top: 1rem;
}

.bb-8c8c8c {
  border-bottom: #8c8c8c solid 1px !important;
}

.custom-range-picker {
  border: #8c8c8c solid 1px !important;
  border-radius: 4px;
}

.custom-range-picker .ant-picker-input > input {
  color: #131717;
  font-weight: 700;
}

.custom-range-picker .ant-picker-input > input::placeholder {
  color: #757575;
  font-weight: 700;
}

.custom-range-picker .ant-picker-active-bar {
  background: none !important;
}

.eventEditor .ql-editor {
  max-height: 188px;
  border-top: #131717 solid 1px;
}

.eventEditor {
  border: #131717 solid 1px;
  height: 15rem;
}

.eventEditor .ql-editor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.eventEditor .ql-editor::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: transparent;
  border-radius: 10px;
}

.eventEditor .ql-editor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.event-input{
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  border-color: #d9d9d9;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.875rem;
  line-height: 2.25rem;
  width: 100%;
}

.event-rendering-section{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
}

.event-date-picker-section svg, .event-add-location-section svg, .btn-event svg {
  width: 21px;
  height: 1rem;
  color: gray;
}
a.ant-picker-now-btn{
  text-decoration: none;
}
.event-date-picker-section, .guest-input-section, .event-add-location-section {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.event-create-text, .event-cevent-text {
  font-weight: 600;
  font-size: 30px;
  color: #000;
}

.event-add-guest-section input {
  padding-right: 0;
  height: 2rem !important;
  border-radius: 4px !important;
  padding-left: 7px !important;
  font-size: .875rem;
  line-height: 1.25rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #F7F8FA;
  border: 0;
}

.event-textarea {
  border: 0px;
  margin-bottom: 10px;
  background: rgb(233, 233, 233);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  height: 104px;
}
.event-textarea:focus-visible {
  outline: none !important;
}

.event-create-button-section {
  display: flex;
  justify-content: end;
}

.event-create-button {
  background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
  border: none;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
}

.guest-drop {
  background: #fff;
  padding: 10px;
  overflow-y: auto;
  max-height: 199px;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
  gap: 5px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  margin-top: 5px;
  z-index: 1;
}

.guestlogo {
  padding: 10px 15px;
  border-radius: 50%;
  background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
  color: #fff;
  font-weight: 600;
  border: none;
}

.guest-drop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: transparent;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
  border-radius: 10px;
}

.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.guest-list-section{
  width: fit-content;
}

.basic-multi-select .css-1wy0on6{
  display: block !important;
}

.basic-multi-select {
  width: -webkit-fill-available;
}

.event-time-picker {
  height: fit-content;
}

td.rdp-day.rdp-selected {
  background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)) !important;
  border-radius: 5px;
  color: #fff;
  opacity: 1;
  border: none;
  -webkit-text-fill-color: #fff !important;
}

td.rdp-day.rdp-selected button{
  border: none !important;
}

.rdp-today:not(.rdp-outside) {
  background: linear-gradient(97.48deg, #18F06E -20%, #0B6DE0 120%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

svg.rdp-chevron {
  fill: #0e8ec3 !important;
}
.parent-div-content-event-preview { 
  width: 70% !important;
  height: 80%;
  overflow: hidden;
}
.ant-modal .buttoncss{
  padding: 10px 20px;
  border-radius: 21px;
  background-color: white;
  font-weight: 600;
  border: rgb(209 213 219) solid 1px;
}

.slot-calendar .rbc-btn-group {
  display: none;
}

.slot-calendar .rbc-time-content {
  max-height: 26.5rem !important;
}

.slot-calendar .rbc-time-header, .rbc-day-slot .rbc-event-label {
  display: none;
}


.slot-calendar .rbc-timeslot-group .rbc-time-slot .rbc-label{
  /* height: 66.5px; */
  height: 38.5px;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 22;
  position: relative;
}
.slot-calendar .rbc-day-slot .rbc-time-slot{
  /* min-height: calc(66.5px / 60); */
}

.slot-calendar .rbc-event-content {
  background: #ffb4b5 !important;
}

.slot-calendar .rbc-day-slot .rbc-event {
  border: none !important;
  opacity: 0.6;
  background-color: transparent !important;
  padding: 0 !important;
}

.slot-calendar .rbc-time-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: transparent;
}

.slot-calendar .rbc-time-content::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: transparent;
  border-radius: 10px;
}

.slot-calendar .rbc-time-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.repet-event-btn {
  padding-left: 30px;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.occurence-input {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  border-color: #d9d9d9;
  padding-left: 0;
  padding-right: 0;
}

.w-24rem {
  width: 24rem;
}

.w-16 {
  width: 4rem;
}

.occurence-can-btn {
  background-color: rgb(229 231 235);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  border: none;
}

.occurence-done-btn {
  border: none !important;
  color: white !important;
}