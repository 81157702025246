/* Navbar CSS */
.navbar {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #000;
}
.nav-menu {
  background-color: #131717;
  width: 20rem;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 10;
}
.nav-menu.active {
  left: 0;
  color: #fff;
  z-index: 1;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 5px 0px;
  list-style: none;
  height: 42px;
}

.nav-text a {
  text-decoration: none !important;
  color: #fff !important;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  text-decoration: none !important;
  color: #fff !important;
}

.navbar-toggle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px 10px;
}

/* start */
img.image-logo-dmail {
  height: 40px;
  width: 40px;
}

.logo-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

h4.dmail-content-h4 {
  flex: none;
  order: 1;
  flex-grow: 0;
  color: #f5f8fa;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 28px;
}

.account-div-content {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 18px;
  background: #1b2020;
  margin: 15px 15px 0;
}

button.compose-gradient-btn {
  background: var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
  display: flex;
  padding: 12px 35px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 14px;
  padding: 12px 35px;
  width: 100%;
  cursor: pointer;
  border: none;
}

.compose-gradient-btn-child {
  display: grid;
  color: #f5f8fa;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.account-sec-navbar span {
  color: var(--text_1-1, #a3abad);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.5px;
}

span.new-msg-font {
  font-weight: 700;
}

.account-div-content div {
  letter-spacing: 0.5px;
  color: white;
}

a.nav-text-a-tag {
  gap: 10px;
}

li.nav-text.activated {
  border-radius: 10px;
  --accent_g: linear-gradient(97deg, rgba(24, 240, 110, 0.1) -20%, rgba(11, 109, 224, 0.1) 120%);
  background: var(--accent_g);
  height: 42px;
  color: white;
  margin: 0 10px;
  position: relative;
}

li.nav-text.in-activated {
  opacity: 70%;
  margin: 0 10px;
}

.dummy-border-append {
  border-radius: 0 16px 16px 0;
  background: var(--green, #45c678);
  width: 7px;
  height: 42px;
  position: absolute;
}

li.nav-text a.nav-text-a-tag span {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.message-type .menu-type-header {
  color: var(--text_1-1, #a3abad);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.menu-header-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 15px 15px;
}

button.edit-list-bth {
  color: var(--primary, #fff);
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  background: none;
  outline: none;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.div-class-foot-quick-child {
  display: flex;
  gap: 10px;
}

.quick-access-content {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 18px;
  background: #1b2020;
}

span.add-new-user-span {
  color: var(--text_1-1, #a3abad);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
}

.circle-add-user {
  width: 35px;
  min-width: 35px;
  height: 35px;
  background: #000000;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  color: aliceblue;
}

span.type-num-count {
  color: var(--green, #18F06E);
  font-weight: 600;
  line-height: normal;
}

.class-scroll-bar-navbar {
  overflow-y: auto;
  max-height: calc(100vh - 250px);
}
/* .class-scroll-bar-navbar {
  scrollbar-width: thin;
  border-radius: 3px;
  scrollbar-color: #45c678 transparent; 
} */

.class-scroll-bar-navbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.class-scroll-bar-navbar::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: transparent;
  border-radius: 10px;
}

.class-scroll-bar-navbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45c678;
}

.div-class-foot-quick-child.cursor-pointer {
  width: 100%;
}
.quick-user.name {
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #ffffff;
}
.leftsidebar-toggle-menu .sidebar {
  margin-left: -20rem;
}
@media (max-width: 1169px) {
 #pro_nav_left {
    display: none;
  }
  .leftsidebar-toggle-menu .sidebar {
      margin-left: 0;
      z-index: 13;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
  }
  .leftsidebar-toggle-menu .overlay {
    display: block;
  }
}
@media (max-width: 767px) {
  .class-scroll-bar-navbar {
    overflow-y: auto;
    max-height: calc(100vh - 170px);
  }
  .leftsidebar-toggle-menu .sidebar {
    width: 18rem;
}
}
