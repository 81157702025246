.signin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.signin-card {
    width: 100%;
    max-width: 600px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.signin-header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #333;
}

.signin-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.signin-logo-container-h4 {
    font-size: 20px;
    margin: 0;
    font-weight: bold;
    color: #333;
}

.signin-header-text {
    margin: 0;
}

.signin-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
}

.signin-subtitle {
    text-align: center;
    font-size: 14px;
    color: gray;
}

.signin-accounts-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.signin-sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 500px;
    padding-right: 10px;
    overflow: hidden;
    overflow-y: auto;
}

.signin-account-button {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #E9EAEB;
    border-radius: 8px;
    background: white;
    transition: background 0.2s;
    width: 100%;
}

.signin-account-button:hover {
    background: #f0f0f0;
}

.signin-account-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.signin-avatar {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    color: #333;
    font-weight: bold;
    border-radius: 50%;
}

.signin-user-details {
    text-align: left;
    width: 100%;
}

.signin-user-name {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.signin-username {
    font-size: 12px;
    color: gray;
    margin: 0;
}

.signin-new-account-button {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #E9EAEB;
    border-radius: 8px;
    background: white;
    transition: background 0.2s;
    width: 98%;
}

.signin-new-account-button:hover {
    background: #f0f0f0;
}

.signin-new-account-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.signin-plus-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    border: 1px solid #E9EAEB;
    font-weight: bold;
    border-radius: 50%;
}

.signin-new-account-text {
    font-size: 14px;
    font-weight: 500;
    color: gray;
    margin: 0;
}

.signin-sidebar-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background-color: transparent;
}

.signin-sidebar-menu::-webkit-scrollbar {
    width: 1px;
    height: 5px;
    background-color: transparent;
    border-radius: 10px;
}

.signin-sidebar-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #45c678;
}