.profile-container {
    background: #fff;
    overflow: hidden;
    position: absolute;
    right: 20px;
    top: 80px;
    z-index: 1;
    padding: 20px 10px;
    max-width: 400px;
    height: auto;
    max-height: 736px;
    box-shadow: 0px 25px 58.2px rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    width: 100%;
}
.close-icon-profile {
    display: flex;
    justify-content: end;
    margin-right: 10px;
}
.profile-name-username-section {
    display: grid;
}
.header-data-profile {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.profile-pic-profile-component {
    background: grey;
    height: 82px;
    width: 82px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    color: white;
}
span.profile-welcome {
    font-style: normal;
    color: #a3abad;
    font-size: 15px;
    font-weight: 300;
    line-height: 15px;
    text-align: left;
}
span.profile-welcome-name {
    color: #131717;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
}

.dot-menu-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 45%;
    padding-right: 5px;
}

.profile-address-switch-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 100%;
    height: auto;
    max-height: 307px;
    background: #eef4f2;
    border-radius: 14px;
    margin-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scrollbar-width: 2px;
}

.profile-address-switch-container {
    scrollbar-width: 2px;
}

.profile-address-switch-container::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    overflow-x: hidden;
}

.profile-address-switch-container::-webkit-scrollbar-thumb {
    background-color: #45c678;
    border-radius: 10px;
    overflow-x: hidden;
}

.profile-address-switch-container::-webkit-scrollbar-thumb:hover {
    overflow-x: hidden;
}

.profile-address-switch-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    overflow-x: hidden;
}
span.text-span-switch-email-heading {
    color: #a3abad;
    font-size: 13px;
    font-weight: 300;
    line-height: 15px;
    text-align: left;
    display: block;
    width: 100%;
}
.row-content-user-address-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    width: 100%;
}
.user-name-section.switch-account {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

span.address.swich-account-address {
    color: #131717;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name.swich-account-name {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    width: 100%;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.content-user-username span {
    border: 1px solid #e2e8eb;
    width: 1px;
    height: 20px;
}
.profile-address-switch-container.merge-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 100%;
    height: auto;
    max-height: 440px;
    background: #eef4f2;
    border-radius: 10px 10px 10px 10px;
    margin-top: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scrollbar-width: 2px;
}

.row-content-user-address-profile.btn-profile-add .add-account-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    color: #a3abad;
    cursor: pointer;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    text-align: left;
}
span.span-text-logout-btn {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    background: linear-gradient(99.84deg, #ffa16c -3.13%, #ff3d40 126.56%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.logout-btn-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 30px;
    gap: 10px;
}

.folder-content {
    scrollbar-width: 2px;
}
.folder-content::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    overflow-x: hidden;
}

.folder-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    overflow-x: hidden;
}

.folder-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    overflow-x: hidden;
}

.folder-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    overflow-x: hidden;
}

.folder-content {
    max-height: 9rem;
    height: 6rem;
}

.sidenav-folder-content {
    color: #f5f8fa;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    width: 15rem;
}
.balance {
    display: none;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e2e8eb;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 10px;
}
.balance .balance-img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1.13px solid #c6cfd440;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}
.balance .balance-img img{
    object-fit: cover;
    width: 100%;
}
.balance span {
    color: #a3abad;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    text-align: left;
}
.balance p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin: 0;
}
.label-profile-radio [type="radio"]:checked {
    box-shadow: 0px 0px 0px 2px #14c693;
    background: linear-gradient(97.48deg, #18f06e -7.9%, #0b6de0 117.63%);
    border-radius: 50%;
    appearance: none;
    border: 3px solid #fff;
    width: 20px;
    height: 20px;
}
.label-profile-radio [type="radio"]:not(:checked) {
    box-shadow: 0px 0px 0px 2px #a3abad;
    background: transparent;
    border-radius: 50%;
    appearance: none;
    width: 20px;
    height: 20px;
}
.merge-start {
    margin-top: 15px;
}
