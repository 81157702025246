
/* .rbc-toolbar .rbc-btn-group {
    display: flex !important;
    gap: 1rem;
  }
  
  .rbc-toolbar .rbc-btn-group:first-child > button:first-child:not(:last-child) {
    width: 118px;
    height: 40px;
    border-radius: 40px !important;
  }
  
  .rbc-toolbar .rbc-btn-group:first-child > button:not(:first-child):not(:last-child) {
    border: none;
  }
  
  .rbc-toolbar .rbc-btn-group:first-child > button:last-child:not(:first-child) {
    border: none;
  } */

  .search-calendar-header {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--strokes-icons, #E2E8EB);
    background: #f0f0f0;
    position: relative;
}

.search-calendar-header input[type="text"] {
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    color: #000000;
  }

.rs-calendar-body {
    background: #ACCFFF1A;
}

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day, .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    background: linear-gradient(97.48deg, #18F06E -20%, #0B6DE0 120%) !important;
}

.rs-calendar-month-dropdown-year-active {
    background: linear-gradient(97.48deg, #18F06E -20%, #0B6DE0 120%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.create-event-button {
    width: 153px;
    height: 58px;
    padding: 15px 28px 13px 20px;
    gap: 12px;
    border-radius: 80px;
    background: var(--accent_g, linear-gradient(97deg, #18F06E -20%, #0B6DE0 120%));
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
/* .rbc-month-view,.rbc-agenda-view,.rbc-time-view {
    margin-top: 20px;
}

.rbc-off-range-bg {
    background: #EFEFEF !important;
}

.rbc-date-cell.rbc-now button{
    background: linear-gradient(97.48deg, #18F06E -20%, #0B6DE0 120%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */

.search-people-header {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #E2E8EB45;
    background: #DDDDDD30;
    position: relative;
}
.search-people-header input[type="text"] {
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    color: #000000;
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: left;

}

.rs-calendar{
height: 340px;
}

.my-calender-header {
    display: flex;
    gap: 12px;
    user-select: none;
    width: fit-content;
}

.my-calender-body {
    line-height: 2.5;
}

.my-calender-body-item {
    display: flex;
    gap: 12px;
    margin-left: 20px;
}

.meet-with-label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #6C6C6C;
}

.my-calender-header-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color:#000000;
}

.event-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.primary-button-text{
    cursor: pointer;
}
.event-preview-header-text {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
}
.compose-email-send input::placeholder{
    display: flex;
    color: var(--text_1-1, #a3abad);
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}
.text-gradient {
    background: linear-gradient(97.48deg, #18F06E -20%, #0B6DE0 120%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.event-preview-header-down-icon svg {
    width: 16.16px;
    height: 10.96px;
}

.event-title-input {
    font-size: 25px;
    font-weight: 400;
    line-height: 37.5px;
    text-align: left;
    border: none;
    outline: none;
    width: 45rem;
    height: 21px;
    font-style: normal;
    color:#3B3B3B99;
}
.receiverEmails{
    display: inline-flex;
    list-style: none;
    padding: 0px !important;
    border-radius: 54px; 
    border:1px solid #ccc
}
.receiverEmails p{
    margin-top:0px;
margin-bottom: 0px;
}
.receiverEmails .receiverPhoto{
    background: var(
        --accent_g,
        linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)
      );
    color:white;
    margin: 0;
    padding: 7px 14px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 5px;
}
.receiverEmails .mainDiv{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 1px;
    
}

.receiverEmails .close_icon{
    margin-left: 5px;
    display: flex
;
    align-items: center;
    margin-right: 5px;
}




.text-editor button{
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 5px;
   
}
.contextMenu .multi_levl_dropdown .contextMenu-dropdown {
    display: none;
}
.multi_levl_dropdown p {
    margin: 0;
    padding: 10px 20px;
}

.contextMenu .multi_levl_dropdown:hover .contextMenu-dropdown {
    display: block !important;
    position: absolute;
    left: 183px;
    border-radius: 5px;
    border: 1px solid #ccc;
    display: block;
    top: 131px;
    width: 291px;
    word-break: break-word;
    background: #ededed;
    max-height: 450px;
    overflow: auto;
}
.contextMenu--option.archive_list{
    padding: 10px 15px !important;
    display: flex;
    align-items: center;
}
.folder_list{
    padding: 10px 15px !important;
    display: flex;
    align-items: center;
}
.modal-loader-contacts .main_div .header_contacts{
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}
.modal-loader-contacts .main_div .header_contacts p{
    margin-bottom: 0;
    margin-left: 5px;
    font-size: 18px;
    font-weight: 500;
}
.modal-loader-contacts .main_div {
  display: flex;
  margin-bottom: 2rem;
}
.modal-loader-contacts{
    width: 85vh !important;
}

.modal-loader-contacts .main_div .inputfield{
    flex: 1;

}
.modal-loader-contacts .main_div .inputfield .serach{
    width: 92%;
    height: 56px;
    border: 0;
    margin-left: 10px;
    background: #61606024;
    border-radius: 6px;
    padding: 20px 10px;
}

.modal-loader-contacts .main_div .inputfield .serach::placeholder{
    font-size: 18px;
}
.modal-loader-contacts .ant-modal-content{
    height: 100%;
    max-height: 700px;
    min-height: 640px;
    padding-top: 41px;
}

.modal-loader-contacts .checkbox_header .checbox_maindiv .checkbox{
    transform: scale(2); 
    -webkit-transform: scale(2); 
}
.modal-loader-contacts .checkbox_header .checbox_maindiv p{
    margin: 0;
    margin-left: 9px;
    font-size: 16px;
}
.modal-loader-contacts .checkbox_header .checbox_maindiv{
    display: flex;
}
.modal-loader-contacts .checkbox_header{
    margin-left: 6px;
    display: flex;
    align-items: center;
}
.modal-loader-contacts .checkbox_header .selectBox{
    text-align: end;
    flex: 1;
}
.modal-loader-contacts .checkbox_header .selectBox .select_options{
    border: 0;
    padding: 10px 25px;
    border-radius: 5px;
    box-shadow: 0px 0px 16px 0px #ccc;
    text-transform: capitalize;
}
/* .modal-loader-contacts .checkbox_header .selectBox .select_options option{
font-size: 18px;
} */
.modal-loader-contacts .checkbox_header{
    margin-bottom: 20px;
}
.modal-loader-contacts .horizontal_line{
    position: absolute;
    top: 174px;
    width: 85vh;
    height: 2px;
    left: 0;
    margin-bottom: 0;
}
.modal-loader-contacts{
    position: relative;
}
.modal-loader-contacts .all_contacts{
    max-height: 450px;
    overflow: auto;
    

}

.modal-loader-contacts .all_contacts.height{
    max-height: 391px;
    overflow: auto;
    

}
.scroll::-webkit-scrollbar {
    width: 5px;
  }
  .scroll::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .scroll::-webkit-scrollbar-thumb {
    background: var(
      --accent_g,
      linear-gradient(97deg, #18f06e -20%, #0b6de0 120%)
    );
    border-radius: 10px;
  }
.modal-loader-contacts .all_contacts .title{
    margin: 0;
    padding-top: 15px;
    font-size: 18px;
    text-transform: capitalize;
    display: flex;
    position: relative;
}
.modal-loader-contacts .all_contacts .title span{
        display: flex;
        position: absolute;
        top: 18px;
        right: 10px;
        align-items: center;
}

.modal-loader-contacts .all_contacts .profile_pic{
    width: 47px;
    margin-right: 20px;
    padding: 8px 13px;
    margin-bottom: 0;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    color: white;
    background:  var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
}
.modal-loader-contacts .all_contacts .checkbox{
    transform: scale(1.5); 
    -webkit-transform: scale(1.5); 
    width: 47px;
    margin-right: 20px;
    transition: 0.5s;
}
.modal-loader-contacts .insert_div{
    position: absolute;
    width: 85vh;
    left: 0;
    bottom: 0px;
    height: 74px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: rgb(232 240 254);
}
.modal-loader-contacts .insert_div .insert_main_div{
    display: flex;
    align-items: center;
    height: 100%;
}
.modal-loader-contacts .insert_div .insert_main_div p{
    margin: 0;
    font-size: 18px;
    margin-left: 27px;
    display: flex;
}

.modal-loader-contacts .insert_div .insert_main_div p span{
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.modal-loader-contacts .insert_div .insert_main_div .button_div{
    flex: 1;
    text-align: end;
    margin-right: 20px;
}
.modal-loader-contacts .insert_div .insert_main_div .button_div button{
    background:  var(--accent_g, linear-gradient(97deg, #18f06e -20%, #0b6de0 120%));
    font-weight: 500;
    border: 0;
    font-size: 18px;
}
.modal-loader-contacts .ant-modal-body{
    max-height: 550px;
}
.modal-loader-contacts .no-message-div{
    height: 385px;
}
.modal-loader-contacts{
    top: 3rem;
}